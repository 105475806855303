<template>
  <div>
    <div class="banner homeImg"></div>
    <div class="container">
      <section class="py-5">
        <h2 class="h2 text-center text-primary mb-5">銷售項目</h2>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-4 text-center mb-5">
              <div class="box">
                <div class="box-img">
                  <img
                    src="https://images.unsplash.com/photo-1645462478540-48da832a8e16?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80"
                    alt="機車零件"
                  />
                </div>
                <h3 class="box-title">機車零件</h3>
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center mb-5">
              <div class="box">
                <div class="box-img">
                  <img
                    src="https://images.unsplash.com/photo-1627530980937-b8721b91506a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=765&q=80"
                    alt="機車配件"
                  />
                </div>
                <h3 class="box-title">機車配件</h3>
              </div>
            </div>
            <div class="col-12 col-lg-4 text-center mb-5">
              <div class="box">
                <div class="box-img">
                  <img
                    src="https://images.unsplash.com/photo-1580402427914-a6cc60d7d44f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2077&q=80"
                    alt="機車修理工具"
                  />
                </div>
                <h3 class="box-title">機車修理工具</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section class="block-secondary">
      <div class="container py-5">
        <h2 class="h2 text-center text-primary mb-5">最新商品</h2>
        <!-- swiper -->
        <swiper
          class="py-5"
          :modules="modules"
          :effect="'coverflow'"
          :breakpoints="swiperOption.breakpoints"
          :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
          }"
          :loop="true"
          :pagination="{ clickable: true }"
        >
          <swiper-slide v-for="item in newProducts" :key="item.id">
            <Card :data="item" />
          </swiper-slide>
        </swiper>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted, inject } from "vue";
import Card from "components/front/Card";
import { fetchNewProducts } from "connection/noAuth/productDB";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Pagination, Autoplay } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle";

export default {
  name: "home",
  components: {
    Swiper,
    SwiperSlide,
    Card,
  },
  setup() {
    const mitt = inject("mitt");
    const newProducts = ref([]);
    const getProducts = async () => {
      try {
        mitt.emit("loading", true);
        let res = await fetchNewProducts();
        newProducts.value = res;
        mitt.emit("loading", false);
      } catch (error) {
        console.log(error);
      }
    };
    onMounted(() => {
      getProducts();
    });

    //swiper
    const swiperOption = ref({
      loop: true,
      preventClicks: false,
      slideToClickedSlide: true,
      breakpoints: {
        320: {
          spaceBetween: 0,
          slidesPerView: 1,
        },
        500: {
          spaceBetween: 50,
          slidesPerView: 2,
        },
        769: {
          spaceBetween: 25,
          slidesPerView: 3,
        },
        992: {
          spaceBetween: 50,
          slidesPerView: 3,
        },
      },
    });

    return {
      newProducts,
      swiperOption,
      modules: [Pagination, Autoplay],
    };
  },
};
</script>
